import { render, staticRenderFns } from "./tongji.vue?vue&type=template&id=423eacec&scoped=true"
import script from "./tongji.vue?vue&type=script&lang=js"
export * from "./tongji.vue?vue&type=script&lang=js"
import style0 from "./tongji.vue?vue&type=style&index=0&id=423eacec&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423eacec",
  null
  
)

export default component.exports