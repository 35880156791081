<template>
  <surround-box
    specialColor="#0b3d51"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
    title="平台信息数据统计"
  >
    <div class="content-box">
      <div class="popbox yangzhi">
        <span>{{ platformInfo.userFarmers }}</span>
        <span>养殖户(家)</span>
      </div>
      <div class="popbox zhongzhi">
        <span>{{ platformInfo.userPlanters }}</span>
        <span>种植户(家)</span>
      </div>
      <div class="popbox zuzhi">
        <span>{{ platformInfo.services }}</span>
        <span>社会化组织(家)</span>
      </div>
      <div class="popbox jisan">
        <span>{{ platformInfo.jiSanDian }}</span>
        <span>集散点(个)</span>
      </div>
      <div class="popbox sheshi">
        <span>{{ platformInfo.facilityCount }}</span>
        <span>设施(个)</span>
      </div>
      <div class="popbox siji">
        <span>{{ platformInfo.drivers }}</span>
        <span>司机(个)</span>
      </div>
      <div class="popbox yunshu">
        <span>{{ platformInfo.cars }}</span>
        <span>运输车辆(辆)</span>
      </div>
      <div class="popbox shebei">
        <span>{{ platformInfo.deviceCount }}</span>
        <span>设备(个)</span>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      platformInfo: {},
    };
  },
  methods: {
    getData() {
      this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO).then((res) => {
        this.platformInfo = res;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 230px;
  position: relative;
  .popbox {
    // background: radial-gradient(
    //   rgba(0, 255, 220, 0) 0%,
    //   rgba(0, 255, 220, 0.2) 70%,
    //   rgba(0, 255, 220, 0.7) 85%,
    //   rgba(0, 255, 220, 1) 100%
    // );
    background: radial-gradient(
      rgba(0, 255, 220, 0) 55%,
      rgba(0, 145, 255, 0.2) 70%,
      rgba(0,145, 255, 0.7) 85%,
      rgba(0, 145, 255, 1) 100%
    );
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > span:first-child {
      color: #36D8FF;
    }
    > span:last-child {
      color: #fff;
    }
  }
  .yangzhi {
    left: 6px;
    top: 6px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .zhongzhi {
    left: 184px;
    top: 20px;
    width: 85px;
    height: 85px;
    border-radius: 45px;
  }
  .zuzhi {
    left: 278px;
    top: 7px;
    width: 116px;
    height: 116px;
    border-radius: 70px;
  }
  .jisan {
    left: 6px;
    top: 120px;
    width: 90px;
    height: 90px;
    border-radius: 50px;
  }
  .sheshi {
    left: 210px;
    top: 116px;
    width: 80px;
    height: 80px;
    border-radius: 55px;
  }
  .siji {
    left: 110px;
    top: 48px;
    width: 70px;
    height: 70px;
    border-radius: 40px;
  }
  .yunshu {
    left: 103px;
    top: 124px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .shebei {
    left: 298px;
    top: 132px;
    width: 90px;
    height: 90px;
    border-radius: 50px;
  }
}
</style>